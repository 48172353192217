<template>
    <v-form ref="form" @submit.prevent="clickSave">
        <v-card v-model="formHasErrors" lazy-validation>
            <v-card-title class="cms_modal__title">
                <span class="text-h6">{{ dialogEdit ? $t('action.edit_course') : $t('action.new_course') }}</span>
                <button type="button" class="btn-close" @click="clickCancel">
                    <v-icon class="fa-solid fa-xmark"></v-icon>
                </button>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="4">
                            <div class="profile__avatar" @click="handleClickAvatar">
                                <img
                                    :src="imageState ? imageState.imgBase64 : cuItem.thumbnail || noImage"
                                    alt="avatar"
                                />
                                <div class="update-avatar">
                                    <v-icon class="fa-solid fa-image"></v-icon>
                                    <input
                                        type="file"
                                        ref="inputImage"
                                        accept="image/jpeg, image/jpg, image/png, image/heic, image/gif"
                                        @change="handleUploadAvatar"
                                        hidden
                                    />
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="8">
                            <v-text-field
                                v-model="cuItem.title"
                                :label="$t('form.name')"
                                ref="Name"
                                required
                                :counter="255"
                                maxlength="255"
                                :rules="[requiredRule]"
                            ></v-text-field>
                            <v-textarea
                                auto-grow
                                rows="2"
                                row-height="15"
                                v-model="cuItem.note"
                                :label="$t('form.administrator_note')"
                                :counter="255"
                                maxlength="255"
                                ref="administrator_note"
                            ></v-textarea>
                            <v-textarea
                                auto-grow
                                rows="2"
                                row-height="15"
                                :label="$t('form.description')"
                                v-model="cuItem.description"
                                ref="description"
                                :counter="255"
                                maxlength="255"
                            >
                            </v-textarea>
                            <v-combobox
                                chips
                                clearable
                                multiple
                                append-icon
                                v-model="cuItem.array_hashtag"
                                :deletable-chips="true"
                                counter="10"
                                :maxLength="10"
                                :disable-lookup="true"
                                :label="$t('form.hashtag')"
                            >
                                <template v-slot:selection="data">
                                    <v-chip
                                        class="hashtag__item"
                                        :key="JSON.stringify(data.item).replace(/\s/g, '')"
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        :disabled="data.disabled"
                                        @click:close="data.parent.selectItem(data.item)"
                                    >
                                        <span>{{ data.item.replace(/\s/g, '') }}</span>
                                        <button
                                            type="button"
                                            class="hashtag__item-delete"
                                            @click="handleDeleteHashtag(data.item)"
                                        >
                                            <a-icon type="close" />
                                        </button>
                                    </v-chip>
                                </template>
                            </v-combobox>
                            <div class="course__time">
                                <label for="">{{ $t('cms.course_time') }}</label>
                                <a-range-picker
                                    :disabledDate="disabledDate"
                                    :placeholder="[$t('cms.start_time'), $t('cms.end_time')]"
                                    :value="[course_settings[0].start_date, course_settings[0].end_date]"
                                    @change="onRangeChange"
                                    style="width: 100%"
                                />
                            </div>
                            <div class="d-flex mt-5">
                                <a-switch v-model="cuItem.status" default-checked />
                                <span class="ml-2">{{ cuItem.status ? $t('cms.public') : $t('cms.private') }}</span>
                            </div>
                        </v-col>

                        <v-col cols="12">
                            <div class="course__assign">
                                <v-checkbox
                                    v-model="isAssignEveryone"
                                    :label="$t('cms.assign_to_everyone')"
                                ></v-checkbox>
                            </div>
                        </v-col>

                        <v-col cols="12" v-if="!isAssignEveryone">
                            <TargetUser :usersAssigned="course_settings[0].assigned" />
                        </v-col>

                        <v-col cols="12">
                            <v-card>
                                <v-toolbar color="primary" dark flat>
                                    <v-toolbar-title class="text-h6">対象コンテンツ設定</v-toolbar-title>
                                </v-toolbar>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-card-text>
                                            <v-treeview
                                                v-model="tree"
                                                :items="items"
                                                selected-color="indigo"
                                                open-on-click
                                                selectable
                                                return-object
                                                item-disabled="locked"
                                                style="height: 500px; overflow: auto"
                                            >
                                                <template v-slot:prepend="{ item }">
                                                    <v-icon dense v-if="item.icon">{{ item.icon }} </v-icon>
                                                    <img :src="item.img" alt="icon" width="20px" v-if="item.img" />
                                                </template>
                                            </v-treeview>
                                        </v-card-text>
                                    </v-col>

                                    <v-divider vertical></v-divider>

                                    <v-col cols="12" md="6">
                                        <v-card-text>
                                            <div
                                                v-if="tree.length === 0"
                                                key="title"
                                                class="text-h6 font-weight-light grey--text pa-4 text-center"
                                            >
                                                {{ $t('cms.select_your_favorite_chapter') }}
                                            </div>
                                            <v-scroll-x-transition group hide-on-leave>
                                                <div
                                                    class="d-flex align-center mb-4"
                                                    v-for="(selection, i) in tree"
                                                    :key="selection.id"
                                                >
                                                    <img
                                                        :src="selection.img"
                                                        alt="icon"
                                                        width="20px"
                                                        v-if="selection.img"
                                                    />
                                                    <span class="ml-2 line-clamp-1" :style="{ fontSize: '16px' }">
                                                        {{ selection.title }}
                                                    </span>
                                                </div>
                                            </v-scroll-x-transition>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions class="cms_modal__actions justify-end">
                <v-btn type="button" color="blue darken-1" text @click="clickCancel"> {{ $t('cancel') }} </v-btn>
                <v-btn type="button" color="blue darken-1" text @click="clickSave"> {{ $t('form.save') }} </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import * as _ from 'lodash';
import { mapState } from 'vuex';
import { mixinRules } from '../../../mixins/rules';
import * as ImageAPI from '../../../api/imageAPI';
import moment from 'moment';
import TargetUser from '../TargetUser';

export default {
    mixins: [mixinRules],
    components: {
        TargetUser,
    },
    props: {
        cuItem: {
            type: Object,
        },
        formTitle: {
            type: String,
        },
        closeCU: { type: Function },
        save: { type: Function },
        propsField: { type: Array },
        columns: { type: Array },
        chapters: { type: Array },
        dialogEdit: { type: Boolean },
        users: { type: Array },
    },
    data() {
        return {
            // selectionType: '',
            imageState: null,
            errorMessages: '',
            formHasErrors: false,
            noImage: require('../../../assets/images/no_image.jpg'),
            tree: [],
            treeUsers: [],
            search: null,
            course_settings: [
                {
                    required: false,
                    assign_everyone: true,
                    assigned: [],
                    start_date: null,
                    end_date: null,
                },
            ],
            isAssignEveryone: true,
        };
    },

    computed: {
        ...mapState('course', ['coursesOne']),
        items() {
            return this.chapters.map((item, index) => ({
                id: item.id,
                name: this.getName(item.title),
                children: this.getChildren(item.sections, item.id),
                locked: item?.sections?.length === 0,
                icon: 'fa-solid fa-layer-group',
            }));
        },
        itemsUsers() {
            return this.users.map((item, index) => ({
                id: item.id,
                name: this.getName(item?.email || ''),
            }));
        },
        form() {
            return {
                avatar: this.cuItem.avatar,
            };
        },
    },

    watch: {
        '$props.cuItem.array_hashtag': function (val) {
            if (val?.length > 10) {
                this.$nextTick(() => this.cuItem.array_hashtag.pop());
            }
        },
        '$props.dialogEdit': function () {
            if (!this.dialogEdit) {
                this.tree = [];
                this.treeUsers = [];
            }
        },
        coursesOne: function () {
            if (this.dialogEdit && !_.isEmpty(this.coursesOne)) {
                let arrayChapter = [];
                const courseSetting = this.coursesOne?.course_settings?.[0];

                this.coursesOne?.course_chapters?.forEach((courseChapter) => {
                    courseChapter?.course_sections?.forEach((courseSection) => {
                        if (courseSection?.section?.lesson) {
                            arrayChapter.push({
                                ...courseSection?.section?.lesson,
                                idChapter: courseSection?.section?.chapter_id,
                                idSection: courseSection?.section?.id,
                            });
                        }

                        if (courseSection?.section?.test) {
                            arrayChapter.push({
                                ...courseSection?.section?.test,
                                idChapter: courseSection?.section?.chapter_id,
                                idSection: courseSection?.section?.id,
                            });
                        }

                        if (courseSection?.section?.form) {
                            arrayChapter.push({
                                ...courseSection?.section?.form,
                                idChapter: courseSection?.section?.chapter_id,
                                idSection: courseSection?.section?.id,
                            });
                        }
                    });
                });

                if (courseSetting) {
                    const settingData = {
                        required: courseSetting?.required,
                        assign_everyone: courseSetting?.assign_everyone,
                        assigned: courseSetting?.course_members
                            ?.filter((item) => item.user)
                            ?.map((item) => ({
                                id: item.user.id,
                                title: item.user.email,
                                type: 'USER',
                                parent_id: null,
                            })),
                        start_date: courseSetting?.start_date
                            ? moment(courseSetting?.start_date).format('YYYY/MM/DD')
                            : null,
                        end_date: courseSetting?.end_date ? moment(courseSetting?.end_date).format('YYYY/MM/DD') : null,
                    };
                    this.course_settings = [settingData];
                    this.isAssignEveryone = settingData.assign_everyone;
                }

                this.tree = arrayChapter;
            }
        },
    },

    methods: {
        moment,
        disabledDate(current) {
            return current && moment().startOf('days').valueOf() > moment(current).valueOf();
        },
        validate() {
            this.formHasErrors = false;

            Object.keys(this.form).forEach((f) => {
                if (!this.form[f]) this.formHasErrors = true;
                this.$refs[f].validate(true);
            });
        },
        resetForm() {
            this.errorMessages = [];
            this.formHasErrors = false;
            this.imageState = null;
            this.$refs.inputImage.value = null;
            this.tree = [];
            this.treeUsers = [];
            this.isAssignEveryone = true;
            this.course_settings = [
                {
                    required: false,
                    assign_everyone: true,
                    assigned: [],
                    start_date: null,
                    end_date: null,
                },
            ];

            Object.keys(this.form).forEach((f) => {
                this.$refs.form.resetValidation();
            });
        },
        async clickSave() {
            const isValid = this.$refs.form.validate();

            if (isValid) {
                this.cuItem.materials = this.chapters
                    .map((item) => this.filterArr(item))
                    .filter((item) => item.chapter_id);
                this.course_settings = [
                    {
                        ...this.course_settings[0],
                        assign_everyone: this.isAssignEveryone,
                        assigned: this.isAssignEveryone
                            ? []
                            : this.course_settings[0].assigned
                                  ?.filter((item) => item?.type === 'USER')
                                  ?.map((item) => item?.id),
                        arr_folder_id: this.isAssignEveryone
                            ? []
                            : this.course_settings[0].assigned
                                  ?.filter((item) => item?.type === 'FOLDER')
                                  ?.map((item) => item?.id),
                        start_date: this.course_settings[0].start_date
                            ? moment(this.course_settings[0].start_date).startOf('days')
                            : null,
                        end_date: this.course_settings[0].end_date
                            ? moment(this.course_settings[0].end_date).endOf('days')
                            : null,
                    },
                ];
                if (this.imageState) {
                    let body = new FormData();
                    body.append('image', this.imageState.file);
                    const res = await ImageAPI.upload(body);
                    this.cuItem.thumbnail = res?.results?.object?.url;
                }

                await this.save(this.course_settings, (isSuccess) => {
                    if (isSuccess) this.clickCancel();
                });
            }
        },
        handleClickAvatar() {
            this.$refs.inputImage && this.$refs.inputImage.click();
        },
        handleUploadAvatar(e) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.imageState = {
                    file,
                    imgBase64: reader.result,
                };
            });
            reader.readAsDataURL(file);
        },
        clickCancel() {
            this.closeCU();
            this.resetForm();
        },
        getChildren(list, id) {
            let arrChild = [];

            list?.forEach((item) => {
                if (item?.lesson || item?.test || item?.form) {
                    arrChild.push({
                        idChapter: id,
                        idSection: item?.id,
                        name: this.getName(item?.lesson?.title || item?.test?.title || item?.form?.title || ''),
                        ...(item?.lesson || item?.test || item?.form),
                        img: this.getIcon(item),
                    });
                }
            });

            return arrChild.sort((a, b) => {
                return a.title > b.title ? 1 : -1;
            });
        },
        getName(name) {
            return `${name?.charAt(0)?.toUpperCase()}${name?.slice(1)}`;
        },
        getIcon(item) {
            if (item?.lesson) {
                return require('../../../assets/images/icon-lesson.png');
            } else if (item?.test) {
                return require('../../../assets/images/icon-test.png');
            } else if (item?.form) {
                return require('../../../assets/images/contact-form.png');
            } else {
                return '';
            }
        },
        filterArr(item) {
            const material = {
                chapter_id: '',
                section_id: [],
            };
            this.tree.map((itemTree) => {
                if (itemTree.idChapter === item.id) {
                    material.chapter_id = item.id;
                    material.section_id.push(itemTree.idSection);
                }
            });
            return material;
        },

        onRangeChange(value, dateString) {
            this.course_settings[0].start_date = dateString[0]
                ? moment(dateString[0]).startOf('days').format('YYYY/MM/DD')
                : null;
            this.course_settings[0].end_date = dateString[1]
                ? moment(dateString[1]).endOf('days').format('YYYY/MM/DD')
                : null;
        },

        handleDeleteHashtag(item) {
            const findIndex = this.cuItem.array_hashtag.findIndex((tag) => tag === item);
            if (findIndex !== -1) {
                this.cuItem.array_hashtag.splice(findIndex, 1);
            }
        },
    },
};
</script>

<style lang="scss">
.course__time {
    & label {
        display: block;
        margin-bottom: 4px;
    }

    & .ant-calendar-picker-input {
        display: flex;
        align-items: center;
    }

    & .ant-calendar-range-picker-separator {
        height: auto;
        line-height: 1;
    }
}

.course__search-user {
    & .v-input__control {
        min-height: 40px !important;
        font-size: 14px;
    }
}

.course__assign {
    & .v-input__slot {
        margin-bottom: 0;
    }

    & .v-messages {
        display: none;
    }
}

.hashtag__item {
    background: #f5f5f5 !important;
    &:hover {
        background: #f5f5f5 !important;
    }
    & .v-chip__content {
        display: flex;
        align-items: center;
    }
    &-delete {
        & i {
            margin-left: 5px;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.45);
            transition: all 0.2s ease;
        }
        & i:hover {
            color: rgba(0, 0, 0, 0.75);
        }
    }
}
</style>
