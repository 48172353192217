var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ padding: 0 }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('h5',{staticClass:"font-semibold m-0"},[_vm._v(_vm._s(_vm.$t('table_title.Course')))])])],1)]},proxy:true}])},[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableColumns,"items":_vm.courses,"show-select":"","hide-default-footer":"","loading":_vm.isLoadingCourse,"loading-text":_vm.$t('cms.loading'),"page":_vm.page,"no-data-text":_vm.$t('cms.no_data_available')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.onAddNewItem()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('cms.add_new'))+" ")],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length > 0),expression:"selected.length > 0"}],staticClass:"mr-2",attrs:{"color":"error","dark":"","small":""},on:{"click":_vm.onDeleteMulti}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v("  "+_vm._s(_vm.$t('form.delete'))+" ")],1)]}}]),model:{value:(_vm.dialogCU),callback:function ($$v) {_vm.dialogCU=$$v},expression:"dialogCU"}},[(_vm.dialogCU)?_c('CourseModal',{attrs:{"cuItem":_vm.cuItem,"formTitle":_vm.formTitle,"closeCU":_vm.closeCU,"save":_vm.onSaveItem,"propsField":_vm.tableColumns,"columns":_vm.tableColumns,"chapters":_vm.chapters,"users":_vm.users,"dialogEdit":_vm.dialogEdit}}):_vm._e()],1),_c('v-spacer'),_c('div',{staticClass:"search"},[_c('a-select',{attrs:{"default-value":_vm.selectedField},on:{"change":_vm.handleChangeSelect}},_vm._l((_vm.fieldsSearch),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1),_c('a-input-search',{attrs:{"placeholder":_vm.$t('header.search')},on:{"change":_vm.onSearchItem},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('ModalDelete',{attrs:{"closeDelete":_vm.closeDelete,"deleteItemConfirm":_vm.deleteItemConfirm}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogNoti),callback:function ($$v) {_vm.dialogNoti=$$v},expression:"dialogNoti"}},[_c('ModalNoti',{attrs:{"close":_vm.closeNoti,"message":_vm.formMessage}})],1)],1)]},proxy:true},{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('v-img',{staticClass:"table-image",attrs:{"src":item.thumbnail || _vm.noImage,"alt":""}})],1)]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2 line-clamp-2"},[_c('span',[_vm._v(_vm._s(item.title))])])]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2 line-clamp-2"},[_c('span',[_vm._v(_vm._s(item.note))])])]}},{key:"item.created_at_unix_timestamp",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('span',[_vm._v(_vm._s(_vm.getFormatDate(+item.created_at_unix_timestamp)))])])]}},{key:"item.updated_at_unix_timestamp",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('span',[_vm._v(_vm._s(_vm.getFormatDate(+item.updated_at_unix_timestamp)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ path: ("/record-of-course/" + (item.id)) }}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"indigo","dark":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi mdi-format-list-bulleted-type ")]),_vm._v("  "+_vm._s(_vm.$t('cms.everyone_course_status'))+" ")],1)],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"info","dark":"","small":""},on:{"click":function($event){return _vm.onUpdateItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v("  "+_vm._s(_vm.$t('form.edit'))+" ")],1),_c('v-btn',{attrs:{"color":"error","dark":"","small":""},on:{"click":function($event){return _vm.onDeleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v("  "+_vm._s(_vm.$t('form.delete'))+" ")],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"text-center py-5"},[_c('f-pagination',{attrs:{"pageSize":_vm.coursesPagination.pageSize,"totalItems":_vm.coursesPagination.totalItems,"disabled":_vm.isLoadingCourse},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]],2)],1)],1),_c('f-notification-dialog',{staticClass:"dialogNoti",attrs:{"isShowDialog":_vm.isShowDialogMulti,"width":"500px","title":_vm.$t('cms.delete_multiple'),"content":_vm.$t('cms.confirm_delete_multiple'),"actions":_vm.actionsMulti}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }