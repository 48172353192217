<template lang="">
    <div class="targetUser">
        <div class="targetUser__top">
            <h5>{{ $t('cms.target_user') }}</h5>
            <a-input-search :placeholder="$t('header.search')" @change="handleSearch" v-if="activeKey === '1'" />
        </div>
        <div class="targetUser__content">
            <div class="targetUser__left">
                <a-tabs v-model="activeKey" default-active-key="1" v-if="!isHideFolder">
                    <a-tab-pane key="1" :tab="$t('table_title.User')">
                        <!-- USER LIST -->
                        <div class="targetUser__userList">
                            <a-checkbox-group :value="checkedUsers">
                                <div
                                    class="targetUser__userItem"
                                    v-for="(user, index) in usersObject.rows"
                                    :key="index"
                                >
                                    <a-checkbox :value="user.id" @change="handleCheckedUser">
                                        <a-icon type="user" />
                                        <span>{{ user.email }}</span>
                                    </a-checkbox>
                                </div>
                            </a-checkbox-group>
                            <div class="targetUser__loading" v-if="isLoading">
                                <a-spin />
                            </div>
                            <div
                                class="targetUser__loadMore"
                                @click="handleMoreUser"
                                v-if="!isLoading && usersObject.count > usersObject.rows.length"
                            >
                                <button>{{ $t('load_more') }}</button>
                            </div>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" :tab="$t('table_title.Folders')">
                        <!-- FOLDER LIST -->
                        <div class="targetUser__folderList">
                            <a-tree
                                v-model="checkedFolders"
                                :expanded-keys="expandedFolders"
                                :selected-keys="selectedFolders"
                                :tree-data="foldersObject.rows"
                                :load-data="onLoadItemFolder"
                                @expand="onExpandFolder"
                                @select="onSelectFolder"
                                @check="onCheckFolder"
                                show-icon
                                checkable
                            >
                                <a-icon slot="folder" type="folder" />
                                <a-icon slot="user" type="user" />
                            </a-tree>
                            <div class="targetUser__loading" v-if="isLoading">
                                <a-spin />
                            </div>
                            <div
                                class="targetUser__loadMore"
                                @click="handleMoreFolder"
                                v-if="!isLoading && foldersObject.count > foldersObject.rows.length"
                            >
                                <button>{{ $t('load_more') }}</button>
                            </div>
                        </div>
                    </a-tab-pane>
                </a-tabs>
                <div class="targetUser__userList" :style="{ maxHeight: '500px' }" v-else>
                    <a-checkbox-group :value="checkedUsers">
                        <div class="targetUser__userItem" v-for="(user, index) in usersObject.rows" :key="index">
                            <a-checkbox :value="user.id" @change="handleCheckedUser">
                                <a-icon type="user" />
                                <span>{{ user.email }}</span>
                            </a-checkbox>
                        </div>
                    </a-checkbox-group>
                    <div class="targetUser__loading" v-if="isLoading">
                        <a-spin />
                    </div>
                    <div
                        class="targetUser__loadMore"
                        @click="handleMoreUser"
                        v-if="!isLoading && usersObject.count > usersObject.rows.length"
                    >
                        <button>{{ $t('load_more') }}</button>
                    </div>
                </div>
            </div>
            <div class="targetUser__right">
                <div :style="{ minHeight: '100%' }">
                    <div class="targetUser__checked" v-for="(item, index) in itemsChoosed" :key="index">
                        <button type="button" @click="onDeleteItemChoosed(item)">
                            <v-icon class="fa-solid fa-trash"></v-icon>
                        </button>
                        <p class="mb-0">
                            <a-icon :type="item.type === 'USER' ? 'user' : 'folder'" />
                            <span class="ml-1">{{ item.title }}</span>
                        </p>
                    </div>
                </div>
                <div class="targetUser__clearAll" v-if="itemsChoosed.length > 0">
                    <button type="button" @click="handleClearAll">{{ $t('cms.clear_all') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as _ from 'lodash';
import { User, UserFolder, FolderItem } from '../../../api';

export default {
    props: {
        usersAssigned: {
            type: Array,
            required: true,
        },
        isHideFolder: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            // OTHER VARIABLES
            isLoading: false,
            searchValue: '',
            activeKey: '1',
            itemsChoosed: [], // [ { id: string, title: string, type: 'USER' | 'FOLDER', parent_id: string | null } ]

            // USER VARIABLES
            currentPageUser: 1,
            usersObject: {
                count: 0,
                rows: [],
            },
            checkedUsers: [],

            // FOLDER VARIABLES
            currentPageFolder: 1,
            foldersObject: {
                count: 0,
                rows: [],
            },
            checkedFolders: [],
            expandedFolders: [],
            selectedFolders: [],
            showIcon: true,
        };
    },
    async created() {
        await this.getUsers();
        if (!this.isHideFolder) await this.getFolders();
        this.initialValues();
    },
    watch: {
        '$props.usersAssigned': function () {
            this.initialValues();
        },
        activeKey: function (val) {
            if (val === '1') {
                this.currentPageUser = 1;
                this.searchValue = '';
                this.getUsers();
            }
        },
    },
    methods: {
        initialValues() {
            this.itemsChoosed = this.usersAssigned;
            const checkedList = this.itemsChoosed.map((item) => item?.id);
            this.checkedUsers = checkedList;
            this.checkedFolders = checkedList;
        },

        async getUsers() {
            this.isLoading = true;
            try {
                const res = await User.getList({
                    fields: ['$all', { company: ['company_code', 'email'] }],
                    where: { email: { $iLike: `%${this.searchValue}%` } },
                    order: [['created_at', 'desc']],
                    page: this.currentPageUser,
                    limit: 10,
                });
                const { count, rows } = res?.results?.objects;
                if (this.currentPageUser === 1) {
                    this.usersObject = { count, rows };
                } else {
                    this.usersObject = {
                        count,
                        rows: this.usersObject.rows.concat(rows),
                    };
                }
            } catch (error) {
                console.log('🚀 ~ file: index.vue:66 ~ getUsers ~ error:', error);
            }
            this.isLoading = false;
        },

        async getFolders() {
            this.isLoading = true;
            try {
                const res = await UserFolder.getList({
                    fields: ['$all'],
                    where: { type: 'USER', parent_id: { $is: null } },
                    order: [['created_at', 'desc']],
                    page: this.currentPageFolder,
                    limit: 10,
                });
                const { count, rows } = res?.results?.objects;
                const newRows = rows.map((item) => ({
                    id: item.id,
                    key: item.id,
                    title: item.title,
                    children: [],
                    slots: {
                        icon: 'folder',
                    },
                    type: 'FOLDER',
                    parent_id: null,
                }));
                if (this.currentPageUser === 1) {
                    this.foldersObject = { count, rows: newRows };
                } else {
                    this.foldersObject = {
                        count,
                        rows: this.foldersObject.rows.concat(newRows),
                    };
                }
            } catch (error) {
                console.log('🚀 ~ file: index.vue:66 ~ getUsers ~ error:', error);
            }
            this.isLoading = false;
        },

        async onLoadItemFolder(treeNode) {
            try {
                const isChecked = treeNode.checked;
                const folder_id = treeNode.dataRef.id;
                let idListChecked = [];
                let itemListChecked = [];

                if (folder_id) {
                    const resFolder = await UserFolder.getList({
                        fields: ['$all'],
                        where: { type: 'USER', parent_id: folder_id },
                        order: [['created_at', 'desc']],
                    });

                    const resUser = await FolderItem.getList({
                        fields: ['$all', { user: ['id', 'email'] }],
                        where: { folder_id, course_id: { $is: null } },
                        order: [['created_at', 'desc']],
                    });

                    const folderList = resFolder?.results?.objects?.rows?.map((item) => {
                        if (isChecked) {
                            idListChecked.push(item?.id);
                            itemListChecked.push({
                                id: item?.id,
                                title: item?.title,
                                type: 'FOLDER',
                                parent_id: folder_id,
                            });
                        }
                        return {
                            id: item?.id,
                            key: item?.id,
                            title: item?.title,
                            children: [],
                            slots: {
                                icon: 'folder',
                            },
                            type: 'FOLDER',
                            parent_id: folder_id,
                        };
                    });

                    const userList = resUser?.results?.objects?.rows?.map((item) => {
                        if (isChecked) {
                            idListChecked.push(item?.user?.id);
                            itemListChecked.push({
                                id: item?.user?.id,
                                title: item?.user?.email,
                                type: 'USER',
                                parent_id: folder_id,
                            });
                        }
                        return {
                            id: item?.user?.id,
                            key: item?.user?.id,
                            title: item?.user?.email,
                            isLeaf: true,
                            slots: {
                                icon: 'user',
                            },
                            type: 'USER',
                            parent_id: folder_id,
                        };
                    });

                    treeNode.dataRef.children = [...folderList, ...userList];
                    this.foldersObject.rows = [...this.foldersObject.rows];

                    if (isChecked) {
                        this.checkedFolders = [...this.checkedFolders, ...idListChecked];
                        this.itemsChoosed = [...this.itemsChoosed, ...itemListChecked];
                    }
                }
            } catch (error) {
                console.log('🚀 ~ file: index.vue:241 ~ onLoadData ~ error:', error);
            }
            return;
        },

        onExpandFolder(expandedKeys) {
            this.expandedFolders = expandedKeys;
        },

        onSelectFolder(selectedKeys, info) {
            this.selectedFolders = selectedKeys;
        },

        onCheckFolder(checkedKeys, info) {
            this.checkedFolders = checkedKeys;

            const isChecked = info.checked;
            const itemChecked = info.node.dataRef;

            this.onChangeItemsChoosed(isChecked, itemChecked);
            this.onChangeCheckedUsers(isChecked, itemChecked?.id);

            if (itemChecked?.children && itemChecked?.children?.length > 0) {
                this.onLoopChildren(isChecked, itemChecked?.children);
            }
        },

        onLoopChildren(isAdd, list) {
            list.forEach((item) => {
                this.onChangeItemsChoosed(isAdd, item);
                this.onChangeCheckedUsers(isAdd, item?.id);
                if (item?.children && item?.children?.length > 0) {
                    this.onLoopChildren(isAdd, item?.children);
                }
            });
        },

        onChangeCheckedUsers(isAdd, id) {
            const findIndex = this.checkedUsers.findIndex((item) => item === id);

            if (isAdd) {
                if (findIndex === -1) this.checkedUsers.push(id);
            } else {
                if (findIndex !== -1) this.checkedUsers.splice(findIndex, 1);
            }
        },

        onChangeCheckedFolders(isAdd, id) {
            const findIndex = this.checkedFolders.findIndex((item) => item === id);

            if (isAdd) {
                if (findIndex === -1) this.checkedFolders.push(id);
            } else {
                if (findIndex !== -1) this.checkedFolders.splice(findIndex, 1);
            }
        },

        onChangeItemsChoosed(isAdd, item) {
            const itemChoosedIndex = this.itemsChoosed.findIndex((itemChoosed) => itemChoosed?.id === item?.id);

            if (isAdd) {
                if (itemChoosedIndex === -1) {
                    this.itemsChoosed.push({
                        id: item?.id,
                        title: item?.title,
                        type: item?.type,
                        parent_id: item?.parent_id,
                    });
                }
            } else {
                if (itemChoosedIndex !== -1) {
                    const el = item;
                    this.itemsChoosed.splice(itemChoosedIndex, 1);
                    if (el?.parent_id) this.onDeleteParent(el?.parent_id);
                }
            }
        },

        async onDeleteItemChoosed(item) {
            this.onChangeCheckedFolders(false, item?.id);
            this.onChangeCheckedUsers(false, item?.id);

            if (item?.type === 'FOLDER') this.onDeleteChildren(item?.id);
            if (item?.parent_id) this.onDeleteParent(item?.parent_id);

            this.onChangeItemsChoosed(false, item);
        },

        onDeleteChildren(parent_id) {
            const list = this.itemsChoosed.filter((item) => item?.parent_id === parent_id);
            list.forEach((item) => {
                this.onDeleteItemChoosed(item);
            });
        },

        onDeleteParent(parent_id) {
            const parentChoosedIndex = this.itemsChoosed.findIndex((itemChoosed) => itemChoosed?.id === parent_id);

            if (parentChoosedIndex !== -1) {
                const parent = this.itemsChoosed[parentChoosedIndex];
                this.itemsChoosed.splice(parentChoosedIndex, 1);
                this.onChangeCheckedFolders(false, parent?.id);
                if (parent?.parent_id) this.onDeleteParent(parent?.parent_id);
            }
        },

        handleCheckedUser(e) {
            const userID = e.target.value;
            const checkedUserIndex = this.checkedUsers.findIndex((id) => id === userID);

            if (checkedUserIndex === -1) {
                this.checkedUsers.push(userID);
                this.onChangeCheckedFolders(true, userID);

                const findUser = this.usersObject.rows.find((user) => user.id === userID);
                if (findUser) {
                    const newItem = {
                        id: findUser.id,
                        title: findUser.email,
                        type: 'USER',
                        parent_id: null,
                    };
                    this.itemsChoosed.push(newItem);
                }
            } else {
                this.checkedUsers.splice(checkedUserIndex, 1);
                this.onChangeCheckedFolders(false, userID);

                const choosedItemIndex = this.itemsChoosed.findIndex((item) => item.id === userID);
                if (choosedItemIndex !== -1) {
                    this.itemsChoosed.splice(choosedItemIndex, 1);
                }
            }
        },

        handleClearAll() {
            this.itemsChoosed.splice(0, this.itemsChoosed.length);
            this.checkedFolders = [];
            this.checkedUsers = [];
        },

        handleMoreUser() {
            this.currentPageUser = this.currentPageUser + 1;
            this.getUsers();
        },

        handleMoreFolder() {
            this.currentPageFolder = this.currentPageFolder + 1;
            this.getFolders();
        },

        handleSearch: _.debounce(function (e) {
            this.searchValue = e.target.value.trim();
            this.currentPageUser = 1;
            this.getUsers();
        }, 500),
    },
};
</script>

<style lang="scss">
.targetUser {
    border: 1px solid #ccc;
    & h5 {
        margin-bottom: 0;
    }
}

.targetUser__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
    min-height: 60px;
    & .ant-input-search {
        width: 100%;
        max-width: 350px;
    }
}

.targetUser__content {
    display: flex;
}

.targetUser__left {
    width: 50%;
    min-height: 100%;
    border-right: 1px solid #ccc;
    & .ant-tabs-nav {
        width: 100%;
    }
    & .ant-tabs-nav .ant-tabs-tab {
        width: 50%;
        margin: 0;
        text-align: center;
    }
    & .ant-tabs-ink-bar {
        width: 50% !important;
    }
}

.targetUser__right {
    position: relative;
    width: 50%;
}

.targetUser__userList,
.targetUser__folderList {
    max-height: 400px;
    overflow-y: scroll;
}

.targetUser__folderList {
    padding: 10px 10px 10px 0;
    & .ant-tree li > span:last-child,
    & .ant-tree li > .ant-tree-node-content-wrapper {
        width: 90%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }
}

.targetUser__right {
    min-height: 100%;
    max-height: 460px;
    overflow-y: scroll;
}

.targetUser__userItem {
    padding: 10px;
    & i {
        margin-right: 5px;
        font-size: 18px !important;
    }
}

.targetUser__loading,
.targetUser__loadMore {
    padding: 15px;
    text-align: center;
    & button {
        color: #1890ff;
    }
}

.targetUser__checked {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    & button i {
        margin-right: 10px;
        font-size: 18px !important;
        transition: all 0.3s ease;
    }
    & button:hover i {
        color: red;
    }
}

.targetUser__clearAll {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0 -5px 10px rgba($color: #000000, $alpha: 0.05);
    z-index: 1;
    & button {
        transition: all 0.4s ease;
    }
    & button:hover {
        color: red;
    }
}
</style>
